/* Mixins */
.linkage-device-select-dialog .link-device {
  padding: 8px;
  background-color: #eee;
  margin: 24px 0;
}
.linkage-device-select-dialog .transform-column, .linkage-device-select-dialog .transform-column-middle {
  height: 45vh !important;
}
.linkage-device-select-dialog .left-transform-column .ul-list {
  max-height: calc(45vh - 48px) !important;
  height: calc(100% - 48px) !important;
}
.linkage-device-select-dialog .tip-text {
  display: inline-block;
  color: #575757;
  margin-top: 8px;
}
.linkage-device-select-dialog .device-name {
  display: inline-block;
  width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.linkage-device-select-dialog .service-area {
  display: inline-block;
  width: 200px;
}
.linkage-device-select-dialog .grey-text {
  color: #999;
}
.linkage-device-select-dialog .search__input {
  width: 100%;
}
.linkage-device-select-dialog .right-close-icon {
  margin-left: 20px;
}